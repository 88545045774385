import FloatingIcon from '@/assets/img/corporate-and-organization/floating_icon@2x.png';
import OnePercentApplyBg from '@/assets/img/corporate-and-organization/one-percent-apply_bg.png';
import OnePercentApplyBgM from '@/assets/img/corporate-and-organization/one-percent-apply_bg_m.png';
import CourtesyArrow from '@/assets/img/corporate-and-organization/one-percent-courtesy_arrow@2x.png';
import CourtesyArrowM from '@/assets/img/corporate-and-organization/one-percent-courtesy_arrow_m@2x.png';
import OnePercentCourtesyBg from '@/assets/img/corporate-and-organization/one-percent-courtesy_bg.jpg';
import OnePercentCourtesyBgM from '@/assets/img/corporate-and-organization/one-percent-courtesy_bg_m.jpg';
import CourtesyIcon1 from '@/assets/img/corporate-and-organization/one-percent-courtesy_icon01.png';
import CourtesyIcon2 from '@/assets/img/corporate-and-organization/one-percent-courtesy_icon02.png';
import CourtesyIcon3 from '@/assets/img/corporate-and-organization/one-percent-courtesy_icon03.png';
import CourtesyIcon4 from '@/assets/img/corporate-and-organization/one-percent-courtesy_icon04.png';
import CourtesyIcon5 from '@/assets/img/corporate-and-organization/one-percent-courtesy_icon05.png';
import ProcessIcon1 from '@/assets/img/corporate-and-organization/one-percent-process_icon01@2x.png';
import ProcessIcon2 from '@/assets/img/corporate-and-organization/one-percent-process_icon02@2x.png';
import ProcessIcon3 from '@/assets/img/corporate-and-organization/one-percent-process_icon03@2x.png';
import ProcessIcon4 from '@/assets/img/corporate-and-organization/one-percent-process_icon04@2x.png';
import ProcessIcon5 from '@/assets/img/corporate-and-organization/one-percent-process_icon05@2x.png';
import OnePercentSwiperBg from '@/assets/img/corporate-and-organization/one-percent-swiper_bg.png';
import OnePercentSwiperCompany1 from '@/assets/img/corporate-and-organization/one-percent-swiper_company01.png';
import OnePercentSwiperCompany2 from '@/assets/img/corporate-and-organization/one-percent-swiper_company02.png';
import OnePercentSwiperCompany3 from '@/assets/img/corporate-and-organization/one-percent-swiper_company03.png';
import OnePercentSwiperCompany4 from '@/assets/img/corporate-and-organization/one-percent-swiper_company04.png';
import OnePercentSwiperImg1 from '@/assets/img/corporate-and-organization/one-percent-swiper_img01.jpg';
import OnePercentSwiperImg1M from '@/assets/img/corporate-and-organization/one-percent-swiper_img01_m.jpg';
import OnePercentSwiperImg2 from '@/assets/img/corporate-and-organization/one-percent-swiper_img02.jpg';
import OnePercentSwiperImg2M from '@/assets/img/corporate-and-organization/one-percent-swiper_img02_m.jpg';
import OnePercentSwiperNext from '@/assets/img/corporate-and-organization/one-percent-swiper_next@2x.png';
import OnePercentSwiperNextOn from '@/assets/img/corporate-and-organization/one-percent-swiper_next_on@2x.png';
import OnePercentSwiperPrev from '@/assets/img/corporate-and-organization/one-percent-swiper_prev@2x.png';
import OnePercentSwiperPrevOn from '@/assets/img/corporate-and-organization/one-percent-swiper_prev_on@2x.png';
import OnePercentLogo from '@/assets/img/corporate-and-organization/one-percent_logo@2x.png';
import IconArrow from '@/assets/img/icons/arrow_right_sky2@2x.png';
import IconDown from '@/assets/img/icons/ico_down@2x.png';
import IconDownWht from '@/assets/img/icons/ico_down_white@2x.png';
import Button from '@/components/Button';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import Row from '@/components/Grid/Row';
import Image from '@/components/Image';
import { InputCheckbox } from '@/components/Input/InputCheckbox';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import OnePercentLayerPopup, {
  ModalFormProps,
} from '@/components/Layer/OnePercentLayerPopup';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Select from '@/components/Select';
import {
  NotiBox,
  NotiItem,
  NotiList,
  NotiParagraph,
} from '@/components/Text/TextNoti';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex, telRegex, websiteRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import { gsap } from 'gsap/all';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link as ScrollLink, Element } from 'react-scroll';
import styled from 'styled-components';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/bundle';
import { PageProps } from 'gatsby';
import {
  OnePercentMiracleControllerService,
  onePercentMiracleCodeVo,
} from '@/__generated__/FrontApi';
import { usePopupStore } from '@/stores/PopupStore';

SwiperCore.use([Autoplay, Navigation]);

const Floating = styled.div`
  width: 303px;
  background: #1cabe2;
  border-radius: 10px;
  overflow: hidden;
  position: fixed;
  bottom: 50px;
  right: 40px;
  z-index: 10;
  cursor: pointer;
  box-shadow: -1.81px 6.72px 20px 0 rgba(0, 0, 0, 0.3);

  a {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 25px 25px 30px;

    .floating-img {
      width: 73px;
    }
    .floating-txt {
      padding-left: 25px;
      p {
        font-size: 20px;
      }
      span {
        font-size: 32px;
        font-weight: 700;
        &::after {
          content: '';
          display: block;
          width: 0;
          height: 2px;
          background: #fff;
          transition: width 0.3s;
        }
      }
    }
  }

  &.on {
    a {
      .floating-txt {
        span {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
  ${breakpoint(1160)} {
    width: 26.12vw;
    bottom: 4.31vw;
    right: 20px;

    a {
      padding: 2.16vw 2.16vw 2.59vw;
      .floating-img {
        width: 6.29vw;
      }
      .floating-txt {
        padding-left: 2.16vw;
        p {
          font-size: 1.72vw;
        }
        span {
          font-size: 2.76vw;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    width: 200px;
    border-radius: 5px;
    bottom: 50px;
    right: 12px;

    a {
      padding: 15px 20px 20px 18px;
      .floating-img {
        width: 45px;
      }
      .floating-txt {
        padding-left: 16px;
        p {
          font-size: 16px;
        }
        span {
          font-size: 22px;
        }
      }
    }
  }
`;
const Section = styled.section`
  br {
    &.br_m {
      display: none;
    }
    ${breakpoint(`mobile`)} {
      &.br_pc {
        display: none;
      }
      &.br_m {
        display: block;
      }
    }
  }
  .section-tit {
    h2 {
      font-size: 48px;
      span {
        color: #1cabe2;
      }
    }
    ${breakpoint(1160)} {
      h2 {
        font-size: 4.14vw;
      }
    }
    ${breakpoint(`mobile`)} {
      h2 {
        font-size: 26px;
      }
    }
  }

  &.section_video {
    padding: 100px 0 0;

    ${Container} {
      display: flex;
      justify-content: space-between;
      margin-bottom: 90px;

      ${Tit} {
        margin-bottom: 30px;
      }
      p {
        line-height: 1.56;
      }
      .onepercent-logo_img {
        width: 379px;
        position: relative;
        z-index: 1;
        animation: growShrink 1s ease-in-out;
      }

      @keyframes growShrink {
        0% {
          opacity: 0;
          transform: scale(1);
        }
        60% {
          opacity: 1;
          transform: scale(1.2);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    ${breakpoint(1160)} {
      padding: 8.62vw 0 0;

      ${Container} {
        margin-bottom: 7.76vw;
        ${Tit} {
          margin-bottom: 2.59vw;
        }
        p {
          font-size: 1.38vw;
        }
        .onepercent-logo_img {
          width: 32.67vw;
        }
      }
    }
    ${breakpoint(`mobile`)} {
      padding: 75px 0 0;

      ${Container} {
        flex-direction: column;
        margin-bottom: 53px;

        ${Tit} {
          margin-bottom: 30px;
        }
        p {
          font-size: 14px;
        }
        .onepercent-logo_wrap {
          margin: 30px auto 0;
          .onepercent-logo_img {
            width: 245px;
          }
        }
      }
    }
  }

  &.section_how {
    position: relative;
    overflow: hidden;
    padding: 90px 0 114px;
    background: linear-gradient(
      to right,
      #1cabe3 0,
      #1cabe3 33.8vw,
      #fff 33.8vw,
      #fff 100%
    );
    ${breakpoint(1160)} {
      padding: 7.76vw 0 9.83vw;
      background: linear-gradient(
        to right,
        #1cabe3 0,
        #1cabe3 22vw,
        #fff 22vw,
        #fff 100%
      );
    }
    ${breakpoint(`mobile`)} {
      padding: 55px 0 70px;
      background: none;

      ${Container} {
        padding: 0;
      }
    }
    .how_bg {
      position: absolute;
      bottom: -80px;
      left: calc(50% + 444px);
      width: 707px;
      ${breakpoint(1160)} {
        display: none;
      }
      ${breakpoint(`mobile`)} {
        display: block;
        left: calc(50% - 6px);
        bottom: 43px;
        width: 320px;
      }
    }
    .flow-text {
      display: inline-block;
      font-size: 240px;
      color: #fff;
      font-weight: 900;
      line-height: 1;
      opacity: 0.05;
      transform: rotate(90deg);
      transform-origin: 0 100%;
      position: absolute;
      top: -180%;
      left: -30px;
      z-index: 3;

      &.on {
        top: -6%;
        transition: all 2s;
      }

      ${breakpoint(1160)} {
        display: none;
      }
      ${breakpoint(`mobile`)} {
        display: block;
        font-size: 60px;
        transform: rotate(0);
        top: 185px;
        left: 0;

        &.on {
          top: 180px;
          left: 40%;
          transition: all 2s;
        }
      }
    }

    .how_wrap {
      width: 100%;
      position: relative;
      .section-tit {
        display: block;
        position: absolute;
        top: 30px;
        left: 50%;
        background: #fff;
        z-index: 2;
      }
      .btn_download {
        display: flex;
        align-items: center;
        width: 310px;
        line-height: 55px;
        padding: 0 20px;
        border: 1px solid #1cabe2;
        background: url(${IconDown}) no-repeat calc(100% - 20px) 50%;
        background-size: 24px;
        font-size: 20px;
        font-weight: 700;
        color: #1cabe2;
        position: absolute;
        left: 50%;
        bottom: 6px;
        z-index: 1;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          background-image 0.15s ease-in-out, border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;

        &:hover {
          background-color: #1cabe2;
          background-image: url(${IconDownWht});
          background-size: 24px;
          border-color: #1cabe2;
          color: #fff;
        }
      }
      ${breakpoint(1160)} {
        .section-tit {
          top: 2.59vw;
        }
        .btn_download {
          width: 26.72vw;
          line-height: 4.74vw;
          padding: 0 1.72vw;
          font-size: 1.72vw;
          bottom: 0.52vw;
          background-position: calc(100% - 1.72vw) 50%;
          background-size: 2.07vw;
        }
      }
      ${breakpoint(`mobile`)} {
        .section-tit {
          position: initial;
          width: 100%;
          padding-left: 20px;
        }
        .btn_download {
          width: calc(100% - 40px);
          line-height: 55px;
          font-size: 16px;
          position: initial;
          margin: 50px auto 0;
          padding: 0 20px;
          justify-content: space-between;
          background-position: calc(100% - 20px) 50%;
          background-size: 24px;
        }
      }
    }

    .swiper-how {
      overflow: visible;
      .swiper-button-prev,
      .swiper-button-next {
        border: 1px solid blue;
        top: 200px;
        margin-top: 0;
        width: 56px;
        height: 56px;
        border: 2px solid #1cabe2;
        border-radius: 100%;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px 11px;
        transition: all 0.2s;
        ${breakpoint(1160)} {
          top: 17.24vw;
          width: 4.83vw;
          height: 4.83vw;
          background-size: 1.73vw 0.95vw;
        }
        ${breakpoint(`mobile`)} {
          margin-top: 0;
          top: calc(76.56vw + 85px);
          width: 56px;
          height: 56px;
          background-size: 20px 10px;
        }
      }
      .swiper-button-next:after,
      .swiper-button-prev:after {
        display: none;
      }
      .swiper-button-prev {
        left: 50%;
        background-image: url(${OnePercentSwiperPrev});
        ${breakpoint(`mobile`)} {
          left: 20px;
        }
      }
      .swiper-button-next {
        left: calc(50% + 76px);
        background-image: url(${OnePercentSwiperNext});
        ${breakpoint(1160)} {
          left: calc(50% + 6.55vw);
        }
        ${breakpoint(`mobile`)} {
          left: 95px;
        }
      }
      .swiper-button-prev:hover {
        background-image: url(${OnePercentSwiperPrevOn});
        background-color: #1cabe2;
      }
      .swiper-button-next:hover {
        background-image: url(${OnePercentSwiperNextOn});
        background-color: #1cabe2;
      }
      .swiper-con {
        display: flex;
        justify-content: space-between;

        ${breakpoint(`mobile`)} {
          flex-direction: column;
          padding-top: 45px;
          margin-top: 40px;
          background: linear-gradient(
            to bottom,
            #1cabe3 0,
            #1cabe3 45vw,
            transparent 45vw,
            transparent 100%
          );
        }
        picture {
          width: 50%;
          max-width: 560px;
          border: 30px solid #fff;
          border-radius: 280px;
          overflow: hidden;
          margin-left: -30px;

          ${breakpoint(1160)} {
            border: 2.59vw solid #fff;
            border-radius: 24.14vw;
            margin-left: -20px;
          }
          ${breakpoint(`mobile`)} {
            width: 100%;
            max-width: 100%;
            border: 15px solid #fff;
            border-radius: 200px;
            margin-left: 0;
          }
        }
        .swiper_txt {
          width: 50%;
          padding-top: 320px;
          ${breakpoint(1160)} {
            font-size: 1.72vw;
            padding-top: 27.59vw;
          }
          ${breakpoint(`mobile`)} {
            width: 100%;
            padding: 110px 20px 0;
          }

          dl {
            display: flex;
            gap: 40px;

            div {
              width: 50%;
              transform: translateY(30px);
              opacity: 0;
              position: relative;

              dt {
                font-size: 30px;
                font-weight: 700;
                color: #1cabe2;
                margin-bottom: 10px;
              }
              dd {
                min-height: 120px;
                .sub_txt {
                  font-size: 22px;
                  line-height: 1.45;
                }
              }
              .sub_blue {
                font-size: 16px;
                color: #1cabe2;
                margin-top: 20px;
              }
            }

            ${breakpoint(1160)} {
              gap: 3.45vw;

              div {
                dt {
                  font-size: 2.59vw;
                  margin-bottom: 0.86vw;
                }
                dd {
                  min-height: 10.34vw;
                  .sub_txt {
                    font-size: 1.9vw;
                  }
                }
                .sub_blue {
                  font-size: 1.38vw;
                  margin-top: 1.72vw;
                }
              }
            }
            ${breakpoint(`mobile`)} {
              flex-direction: column;
              gap: 50px;

              div {
                width: 100%;
                img {
                  max-width: 158px;
                }

                dt {
                  font-size: 21px;
                  margin-bottom: 15px;
                }
                dd {
                  min-height: auto;
                  margin-bottom: 25px;
                  .sub_txt {
                    font-size: 17px;
                    line-height: 1.29;
                  }
                }
                .sub_blue {
                  font-size: 14px;
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
      .swiper-slide-active {
        .swiper_txt {
          dl {
            div {
              transform: translateY(0);
              opacity: 1;
              &:first-child {
                transition: all 0.3s 0.5s;
              }
              &:last-child {
                transition: all 0.3s 0.7s;
              }
            }
          }
        }
      }
    }
  }

  &.section_process {
    padding: 100px 0 120px;
    background: #e2f0f6;

    .section-tit {
      text-align: center;
    }
    .process_wrap {
      margin-top: 50px;
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          text-align: center;
          margin: 0 auto;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 14px;
            height: 25px;
            background: url(${IconArrow}) no-repeat 0 0;
            background-size: 100% 100%;
            position: absolute;
            top: 70px;
            right: -39px;
          }

          .process_step {
            .process_icon {
              width: 160px;
              height: 160px;
              background-color: #fff;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              picture {
                width: auto;
              }
            }
          }

          .process_txt {
            margin-top: 20px;
            p {
              font-size: 22px;
            }
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    ${breakpoint(1160)} {
      padding: 8.62vw 0 10.34vw;

      .process_wrap {
        margin-top: 4.31vw;

        ul {
          li {
            &:nth-child(1) {
              .process_icon picture {
                width: 5.34vw;
              }
            }
            &:nth-child(2) {
              .process_icon picture {
                width: 4.05vw;
              }
            }
            &:nth-child(3) {
              .process_icon picture {
                width: 7.16vw;
              }
            }
            &:nth-child(4) {
              .process_icon picture {
                width: 6.56vw;
              }
            }
            &:nth-child(5) {
              .process_icon picture {
                width: 5.86vw;
              }
            }

            &::after {
              width: 1.21vw;
              height: 2.16vw;
              top: 6.03vw;
              right: -3.36vw;
            }

            .process_step {
              .process_icon {
                width: 13.79vw;
                height: 13.79vw;
              }
              .process_txt {
                margin-top: 1.72vw;
                p {
                  font-size: 1.9vw;
                }
              }
            }
          }
        }
      }
    }
    ${breakpoint(`mobile`)} {
      padding: 65px 0 70px;

      .process_wrap {
        margin-top: 45px;

        ul {
          max-width: 320px;
          margin: 0 auto;

          li {
            width: 50%;
            margin: 0 0 30px 0;

            .process_step {
              max-width: 112px;

              .process_icon {
                width: 100px;
                height: 100px;
                margin: 0 auto;
              }
              .process_txt {
                margin-top: 2.16vw;
                p {
                  font-size: 16px;
                }
              }
            }

            &::after {
              width: 8px;
              height: 13px;
              top: 43px;
              right: 0;
            }

            &:nth-child(1) {
              .process_icon picture {
                width: 40px;
              }
            }
            &:nth-child(2) {
              .process_icon picture {
                width: 32px;
              }
            }
            &:nth-child(3) {
              .process_icon picture {
                width: 46px;
              }
            }
            &:nth-child(4) {
              .process_icon picture {
                width: 48px;
              }
            }
            &:nth-child(5) {
              .process_icon picture {
                width: 45px;
              }
            }

            &:nth-of-type(2n-1) {
              &::after {
                right: 6px;
              }
              .process_step {
                margin-left: 5px;
              }
            }
            &:nth-of-type(2n) {
              .process_step {
                margin-left: 12px;
              }
            }
          }
        }
      }
    }
  }

  &.section_apply {
    padding: 100px 0 120px;
    background: url(${OnePercentApplyBg}) no-repeat calc(50% - 323px)
      calc(100% + 128px);
    background-size: 729px 847px;
    position: relative;

    .bg_source {
      display: none;
    }
    ${breakpoint(1160)} {
      padding: 8.62vw 0 10.34vw;
      background-size: 62.84vw 73.02vw;
      background-position: calc(50% - 28.84vw) calc(100% + 11.03vw);
    }
    ${breakpoint(`tablet`)} {
      padding: 8.62vw 0 36.34vw;
    }
    ${breakpoint(`mobile`)} {
      padding: 65px 0 380px;
      background: url(${OnePercentApplyBgM}) no-repeat -25px calc(100% + 60px);
      background-size: 280px 423px;

      .bg_source {
        display: block;
        position: absolute;
        right: 20px;
        bottom: 30px;
        font-size: 14px;
        opacity: 0.3;
        transform: rotate(90deg);
        transform-origin: 100% 0;
      }
    }
    ${Container} {
      display: flex;
      ${breakpoint(`tablet`)} {
        flex-direction: column;
      }

      .section-tit {
        width: 34%;
        flex-shrink: 0;
        p {
          font-size: 16px;
          line-height: 1.75;
          margin-top: 40px;
          strong {
            font-weight: 700;
            color: #1cabe2;
          }
        }
        ${breakpoint(1160)} {
          p {
            font-size: 1.38vw;
            margin-top: 1.72vw;
          }
        }
        ${breakpoint(`tablet`)} {
          width: 100%;
          p {
            font-size: 14px;
          }
        }
        ${breakpoint(`mobile`)} {
          p {
            margin-top: 30px;
            line-height: 1.79;
          }
        }
      }
      form {
        margin-left: 55px;

        ${FormGroup} {
          width: 100%;

          .chk-label {
            line-height: 22px;
          }
        }
        ${Row} {
          align-items: flex-start;
          ${FormGroup} {
            width: calc(50% - 10px);
          }
        }
        .agree-dl {
          margin-top: 25px;
          dt {
            line-height: 1.7;
          }
          dd {
            ${NotiBox} {
              margin-bottom: 0;
              padding: 20px 25px;
              ${NotiItem} {
                font-weight: 400;
                margin-top: 0;

                &::after {
                  top: 11px;
                }
              }
              ${NotiParagraph} {
                font-weight: 400;
                color: #1cabe2;
                padding-left: 14px;
              }
            }
          }
        }
        .checkbox_wrap {
          margin: 40px auto;
          max-width: 270px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          ${ErrorMessage} {
            width: 100%;
            text-align: center;
          }
        }
        .btn_agree_pop {
          line-height: 20px;
          color: #1cabe2;
          margin-left: 10px;
          padding-bottom: 1px;
          border-bottom: 1px solid #1cabe2;
        }

        ${breakpoint(1160)} {
          margin-left: 4.74vw;
        }
        ${breakpoint(`tablet`)} {
          margin-left: 0;
          margin-top: 40px;
        }
        ${breakpoint(`mobile`)} {
          ${Row} {
            flex-direction: column;
            ${FormGroup} {
              width: 100%;
            }
          }
          ${InputTextarea} {
            height: 140px;
          }
          .agree-dl {
            dd {
              ${NotiBox} {
                padding: 20px 15px;
                ${NotiParagraph} {
                  padding-left: 8px;
                }
              }
            }
          }
          .checkbox_wrap {
            max-width: 280px;
            margin: 30px auto;
          }
        }
      }
    }
  }

  &.section_courtesy {
    background: url(${OnePercentCourtesyBg}) no-repeat 50% 50%;
    background-size: cover;
    padding: 100px 0 120px;
    ${breakpoint(1160)} {
      padding: 8.62vw 0 10.34vw;
    }
    ${breakpoint(`mobile`)} {
      background: url(${OnePercentCourtesyBgM}) no-repeat 50% 50%;
      background-size: auto 100%;
      padding: 65px 0 70px;
    }

    .section-tit {
      text-align: center;
      color: #fff;
      h2 span {
        color: #ffc20e;
      }
    }
    ${Container} {
      padding: 0;
      position: relative;
      ${breakpoint(1160)} {
        padding: 0 20px;
      }

      ul {
        display: flex;
        margin-top: 50px;
        padding: 40px 20px;
        justify-content: space-between;
        background: #fff;
        border-radius: 20px;
        box-shadow: -9.49px 11.67px 51px 0 rgba(55, 78, 162, 0.45);

        li {
          border-right: 1px solid transparent;
          border-image: repeating-linear-gradient(
              0deg,
              #bae6f6,
              #bae6f6 1px,
              transparent 1px,
              transparent 2px
            )
            1;
          width: 20%;
          padding: 0 30px 10px;
          text-align: center;
          position: relative;

          &:last-child {
            border-right: 0;
          }

          .courtesy_icon {
            width: 100px;
            margin: 0 auto 20px;
          }

          .courtesy_txt {
            dt {
              font-size: 26px;
              font-weight: 700;
              color: #1cabe2;
              margin-bottom: 10px;
            }
            dd {
              span {
                display: inline-block;
                font-size: 14px;
                color: #1cabe2;
                margin-top: 10px;
              }
            }
          }
        }

        & + p {
          color: #fff;
          opacity: 0.8;
          text-align: center;
          margin-top: 25px;
        }

        ${breakpoint(1160)} {
          margin-top: 4.31vw;
          padding: 3.45vw 1.72vw;
          border-radius: 1.72vw;

          li {
            border-radius: 1.72vw;
            padding: 0 1.72vw 0.86vw;

            &::after {
              width: 3.45vw;
              height: 0.95vw;
            }

            .courtesy_icon {
              width: 8.62vw;
              margin: 0 auto 1.72vw;
            }

            .courtesy_txt {
              dt {
                font-size: 2.24vw;
                margin-bottom: 0.86vw;
              }
              dd {
                font-size: 1.38vw;
                span {
                  font-size: 1.38vw;
                  margin-top: 0.86vw;
                }
              }
            }
          }
        }
        ${breakpoint(`mobile`)} {
          flex-direction: column;
          justify-content: center;
          border-radius: 10px;
          padding: 0 25px;
          margin-top: 38px;
          box-shadow: 5px 6px 25px 0 rgba(55, 78, 162, 0.45);

          li {
            width: 100%;
            flex-direction: column;
            padding: 30px 0;
            border-right: 0;
            border-bottom: 1px solid transparent;
            border-image: repeating-linear-gradient(
                90deg,
                #1cabe2 0,
                #1cabe2 1px,
                transparent 1px,
                transparent 4px
              )
              1;

            .courtesy_icon {
              width: 75px;
              margin-bottom: 20px;
            }
            &::after {
              width: 10px;
              height: 40px;
              background: url(${CourtesyArrowM}) no-repeat;
              background-size: contain;
              position: absolute;
              top: initial;
              bottom: -20px;
              right: 50%;
              transform: translateX(50%);
            }
            &:last-child {
              border-bottom: 0;
              &::after {
                display: none;
              }
            }
            .courtesy_txt {
              dt {
                font-size: 19px;
                margin-bottom: 10px;
              }
              dd {
                font-size: 14px;
                span {
                  font-size: 13px;
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const ButtonContainer = styled.div`
  text-align: center;
  ${Button} {
    width: 267px;
    padding: 15px;
    border-radius: 30px;
    font-size: 20px;
    box-shadow: 4px 3px 16px 0 rgba(0, 0, 0, 0.3);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${breakpoint(1160)} {
      width: 23vw;
      padding: 1.29vw;
      font-size: 1.72vw;
    }
    ${breakpoint(`mobile`)} {
      width: 100%;
      max-width: 280px;
      padding: 10px;
      border-radius: 23px;
      box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.3);
      font-size: 16px;
    }
  }
`;
const SectionContact = styled.section`
  padding: 120px 0 240px;
  ${breakpoint(1160)} {
    padding: 120px 0 120px;
  }
`;

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `기업후원팀`,
    tel: `02-721-5121~9`,
    email: `onepercentmiracle@unicef.or.kr`,
  },
];

export interface OnePercentApplyData {
  memberYn: string;
  supportTypeCd: string;
  supportName: string;
  chargeName: string;
  phone: string;
  email: string;
  siteUri: string;
  inquiry: string;
  prsnlInfAgrYn: string;
}

const Miracle: React.FC<PageProps> = ({ location }) => {
  const swiperRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const popupStore = usePopupStore();

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);
  const [agreeCheck, setAgreeCheck] = useState(false);

  // 코드 목록 조회
  const [listSupportType, setListSupportType] = useState([]);
  const loadCodeList = useCallback(async () => {
    OnePercentMiracleControllerService.selectOnePercentMiracleCodeListUsingGet({
      codeGrp: 'SUPPORT_TYPE_CD',
    })
      .then(({ data, resultCode }) => {
        setListSupportType([]);
        if (resultCode === `success` && data) {
          const item = data as onePercentMiracleCodeVo[];

          item.sort((a, b) =>
            Number(a.orderNumber) < Number(b.orderNumber)
              ? -1
              : Number(a.orderNumber) > Number(b.orderNumber)
              ? 1
              : 0,
          );
          setListSupportType(item);
        }
      })
      .catch(() => {
        setListSupportType([]);
      });
  }, []);

  useEffect(() => {
    loadCodeList();
  }, [loadCodeList]);

  useEffect(() => {
    // swiper bg text flowing animation
    const txtFlowing = () => {
      setTimeout(() => {
        document.querySelector('.flow-text')?.classList.add('on');
      }, 0);
    };
    document.addEventListener('aos:in:aosTrigger', txtFlowing);

    const handleSwiper = () => {
      if (swiperRef.current && swiperRef.current.autoplay) {
        swiperRef.current.autoplay.start();
      }
    };
    document.addEventListener('aos:in:aosTrigger', handleSwiper);

    AOS.init({
      // Global settings
      duration: 800,
      easing: 'ease-in-out',
      once: true,
      offset: 100,
    });
  }, []);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<OnePercentApplyData>({
    defaultValues: {
      /*
      memberYn: ``,
      supportName: ``,
      chargeName: ``,
      phone: ``,
      email: ``,
      siteUri: ``,
      supportTypeCd: ``,
      inquiry: ``,
      prsnlInfAgrYn: `N`,
      */
    },
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const onSubmitForm: SubmitHandler<OnePercentApplyData> = useCallback(
    async (formData) => {
      if (isUpdate === true) {
        popupStore.show('신청 처리중입니다.\n잠시만 기다려주세요.');
        return;
      }
      setIsUpdate(true);

      formData.prsnlInfAgrYn = formData.prsnlInfAgrYn || 'N';
      // submit data
      try {
        const submitRes: any = await OnePercentMiracleControllerService.insertOnePercentMiracleApplyUsingPost(
          formData,
        );
        if (submitRes?.resultCode === 'success') {
          // success
          popupStore.show('신청해주셔서 감사합니다!\n곧 연락드리겠습니다.');
          reset();
        } else {
          // error
          popupStore.show(
            '1%의 기적신청에 실패했습니다.\n잠시후에 다시 시도해주세요.(0)',
          );
        }
      } catch (e) {
        popupStore.show(
          '1%의 기적신청에 실패했습니다.\n잠시후에 다시 시도해주세요.(1)',
        );
      }
      setIsUpdate(false);
    },
    [isUpdate],
  );

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="1%의 기적"
      description="for every child, 1% miracle"
    >
      <Floating
        className={isHovering ? 'on' : ''}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <ScrollLink to="SectionApply" smooth duration={500} offset={-55}>
          <img
            src={FloatingIcon}
            alt="신청하기 연필 아이콘"
            className="floating-img"
          />
          <div className="floating-txt">
            <p>1%의 기적</p>
            <span>신청하기</span>
          </div>
        </ScrollLink>
      </Floating>
      <Section className="section_video">
        <Container>
          <div className="title_wrap">
            <Tit size="s1">
              1%의 나눔으로 <br />
              시작되는 100%의 기적
            </Tit>
            <p>
              수 많은 어린이들의 세상을 바꾼 건 <br />
              평범한 일상 속 1% 나눔
              <br />
              유니세프는 더 오래, 더 많이, 더 멀리
              <br />전 세계 어린이가 건강한 어른이 될 권리를 만듭니다
            </p>
          </div>
          <div className="onepercent-logo_wrap">
            <img
              src={OnePercentLogo}
              alt="1%의 기적, 유니세프 로고"
              className="onepercent-logo_img"
            />
          </div>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="X_mnXcitcZQ" />
        </PageComponent>
      </Section>
      <Section className="section_how">
        <div className="flow-text">miracle</div>
        <img src={OnePercentSwiperBg} className="how_bg" alt="" />
        <Container
          className="how_wrap"
          data-aos="fade-up"
          data-aos-id="aosTrigger"
        >
          <div className="section-tit">
            <h2>
              다양한 <span>후원 방법</span>을 <br />
              소개합니다
            </h2>
          </div>
          <Swiper
            className="swiper-how"
            effect="fade"
            navigation
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            loop
            speed={500}
            initialSlide={0}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              swiper.autoplay.stop();
            }}
          >
            <SwiperSlide>
              <div className="swiper-con">
                <Image
                  pcSrc={OnePercentSwiperImg1}
                  mobileSrc={OnePercentSwiperImg1M}
                />
                <div className="swiper_txt">
                  <dl>
                    <div>
                      <dt>1% 수익 기부</dt>
                      <dd>
                        <p className="sub_txt">
                          수익 또는 판매액의 <br className="br_pc" />
                          1%를 기부
                        </p>
                      </dd>
                      <img src={OnePercentSwiperCompany1} alt="DAIZ" />
                      <p className="sub_blue">
                        이마트 데이즈, 그립톡 1000원 기부 모델
                      </p>
                    </div>
                    <div>
                      <dt>이벤트 기부</dt>
                      <dd>
                        <p className="sub_txt">
                          다양한 행사를 통해 발생한 수익금 혹은 후원금 기부
                        </p>
                      </dd>
                      <img
                        src={OnePercentSwiperCompany2}
                        alt="sm entertainment"
                      />
                      <p className="sub_blue">
                        롯데자이언츠, <br className="br_pc" />
                        유니세프 시리즈 이벤트 기금 모금
                      </p>
                    </div>
                  </dl>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-con">
                <Image
                  pcSrc={OnePercentSwiperImg2}
                  mobileSrc={OnePercentSwiperImg2M}
                />
                <div className="swiper_txt">
                  <dl>
                    <div>
                      <dt>끝전 기부</dt>
                      <dd>
                        <p className="sub_txt">
                          상품 판매 또는 임직원이 급여의 일정 금액(끝전)을 <br className="br_pc" />
                          모아 기부
                        </p>
                      </dd>
                      <img src={OnePercentSwiperCompany3} alt="Otsuka" />
                      <p className="sub_blue">
                        한국오츠카제약, 임직원 급여끝전기부
                      </p>
                    </div>
                    <div>
                      <dt>포인트 기부</dt>
                      <dd>
                        <p className="sub_txt">
                          고객 또는 임직원이 보유한 각종 포인트를 자유롭게 기부
                        </p>
                      </dd>
                      <img
                        src={OnePercentSwiperCompany4}
                        alt="Lotte hotels & resorts"
                      />
                      <p className="sub_blue">
                        롯데호텔앤리조트, <br className="br_pc" />
                        LH 포인트 기부 프로그램
                      </p>
                    </div>
                  </dl>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <LinkSafe
            to={`${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9taXJhY2xl&dispFileNm=one_percent_miracle_toolkit.pdf&svrFileNm=one_percent_miracle_toolkit.pdf`}
            className="btn_download"
          >
            1%의 기적 툴킷 다운로드
          </LinkSafe>

          {/* <a
            href="/involve/corporate-and-organization/pdf/onepercent_toolkit.pdf"
            download="onepercent_toolkit.pdf"
            title="1%의 기적 툴킷 다운로드"
            className="btn_download"
          >
            1%의 기적 툴킷 다운로드
          </a> */}
        </Container>
      </Section>
      <Section className="section_process">
        <Container>
          <div className="section-tit">
            <h2 data-aos="fade-up">
              다음과 같은 방법으로 <br className="br_m" />
              <span>참여해 주세요</span>
            </h2>
          </div>
          <div className="process_wrap">
            <ul>
              <li data-aos="fade-in" data-aos-delay="0">
                <div className="process_step">
                  <div className="process_icon">
                    <Image pcSrc={ProcessIcon1} mobileSrc={ProcessIcon1} />
                  </div>
                  <div className="process_txt">
                    <p>신청서 작성(하단)</p>
                  </div>
                </div>
              </li>
              <li data-aos="fade-in" data-aos-delay="100">
                <div className="process_step">
                  <div className="process_icon">
                    <Image pcSrc={ProcessIcon2} mobileSrc={ProcessIcon2} />
                  </div>
                  <div className="process_txt">
                    <p>후원 협의</p>
                  </div>
                </div>
              </li>
              <li data-aos="fade-in" data-aos-delay="200">
                <div className="process_step">
                  <div className="process_icon">
                    <Image pcSrc={ProcessIcon3} mobileSrc={ProcessIcon3} />
                  </div>
                  <div className="process_txt">
                    <p>후원 진행</p>
                  </div>
                </div>
              </li>
              <li data-aos="fade-in" data-aos-delay="300">
                <div className="process_step">
                  <div className="process_icon">
                    <Image pcSrc={ProcessIcon4} mobileSrc={ProcessIcon4} />
                  </div>
                  <div className="process_txt">
                    <p>
                      기부금 <br className="br_m" />
                      영수증 발급
                    </p>
                  </div>
                </div>
              </li>
              <li data-aos="fade-in" data-aos-delay="400">
                <div className="process_step">
                  <div className="process_icon">
                    <Image pcSrc={ProcessIcon5} mobileSrc={ProcessIcon5} />
                  </div>
                  <div className="process_txt">
                    <p>
                      후원 리포트 <br className="br_m" />
                      제공
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </Section>

      <Element name="SectionApply" />
      <Section className="section_apply">
        <span className="bg_source">© UNICEF/UN0326766/Moreno Gonzalez</span>
        <Container>
          <div className="section-tit">
            <h2 data-aos="fade-up">
              <span>1%의 기적 </span> <br className="br_pc" />
              신청서 작성
            </h2>
            <p data-aos="fade-up">
              작은 나눔을 더해서 전 세계 어린이가
              <br />
              <strong> 더 큰 미래를 꿈꿀 수 있는 기적</strong>을 만들어주세요.
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="form-container" data-aos="fade-up">
              <Row>
                <FormGroup className="FormGroup">
                  <LabelText require>희망하는 후원방법</LabelText>
                  <Select
                    name="supportTypeCd"
                    ref={register({
                      required: {
                        value: true,
                        message: `후원방법을 선택해주세요.`,
                      },
                      validate: (value) =>
                        value !== '' || '후원방법을 선택해주세요.',
                    })}
                    defaultValue=""
                  >
                    <option value="">희망하는 후원방법을 선택해주세요.</option>
                    {listSupportType.map((item: onePercentMiracleCodeVo) => {
                      return (
                        <option value={`${item.codeKey}`}>
                          {item.codeVal}
                        </option>
                      );
                    })}
                    {/*
                      <option value={SUPPORT_TERM_REGULAR}>정기</option>
                      <option value={SUPPORT_TERM_ONCE}>일시</option>
                       */}
                  </Select>
                  {errors.supportTypeCd && (
                    <ErrorMessage>{errors.supportTypeCd.message}</ErrorMessage>
                  )}
                </FormGroup>
                <FormGroup className="FormGroup">
                  <LabelText require>기업·단체명</LabelText>
                  <InputText
                    placeholder="기업·단체명 입력"
                    name="supportName"
                    ref={register({
                      required: {
                        value: true,
                        message: `기업·단체명을 입력해주세요.`,
                      },
                      maxLength: {
                        value: 30,
                        message: `30자 이내로 입력해주세요.`,
                      },
                    })}
                  />
                  {errors.supportName && (
                    <ErrorMessage>{errors.supportName.message}</ErrorMessage>
                  )}
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="FormGroup">
                  <LabelText require>담당자명</LabelText>
                  <InputText
                    placeholder="담당자명 입력"
                    name="chargeName"
                    ref={register({
                      required: {
                        value: true,
                        message: `담당자명을 입력해주세요.`,
                      },
                      maxLength: {
                        value: 20,
                        message: `20자 이내로 입력해주세요.`,
                      },
                    })}
                  />
                  {errors.chargeName && (
                    <ErrorMessage>{errors.chargeName.message}</ErrorMessage>
                  )}
                </FormGroup>
                <FormGroup className="FormGroup">
                  <LabelText require>연락처</LabelText>
                  <InputText
                    placeholder="연락처 입력 ( &lsquo;-&rsquo; 제외 )"
                    name="phone"
                    ref={register({
                      required: {
                        value: true,
                        message: `연락처를 입력해주세요.`,
                      },
                      pattern: {
                        value: telRegex,
                        message: `올바른 연락처가 아닙니다. ( '-' 제외 )`,
                      },
                    })}
                  />
                  {errors.phone && (
                    <ErrorMessage>{errors.phone.message}</ErrorMessage>
                  )}
                </FormGroup>
              </Row>
              <Row>
                <FormGroup className="FormGroup">
                  <LabelText require>이메일</LabelText>
                  <InputText
                    placeholder="이메일주소 입력"
                    name="email"
                    ref={register({
                      required: {
                        value: true,
                        message: `이메일을 입력해주세요.`,
                      },
                      pattern: {
                        value: emailRegex,
                        message: `올바른 이메일 형식이 아닙니다.`,
                      },
                    })}
                  />
                  {errors.email && (
                    <ErrorMessage>{errors.email.message}</ErrorMessage>
                  )}
                </FormGroup>
                <FormGroup className="FormGroup">
                  <LabelText>기업·단체 웹사이트 주소</LabelText>
                  <InputText
                    placeholder="기업·단체 웹사이트 주소 입력"
                    name="siteUri"
                    ref={register({
                      pattern: {
                        value: websiteRegex,
                        message: `올바른 웹사이트 주소 형식이 아닙니다.`,
                      },
                      maxLength: {
                        value: 200,
                        message: `200자 이내로 입력해주세요.`,
                      },
                    })}
                  />
                  {errors.siteUri && (
                    <ErrorMessage>{errors.siteUri.message}</ErrorMessage>
                  )}
                </FormGroup>
              </Row>
              <FormGroup className="FormGroup">
                <LabelText>문의 사항</LabelText>
                <InputTextarea
                  placeholder="문의 사항을 입력해 주세요."
                  name="inquiry"
                  ref={register({
                    maxLength: {
                      value: 500,
                      message: `500자 이내로 입력해주세요.`,
                    },
                  })}
                />
                {errors.inquiry && (
                  <ErrorMessage>{errors.inquiry.message}</ErrorMessage>
                )}
              </FormGroup>

              <dl className="agree-dl">
                <dt>개인정보 수집 및 이용 동의</dt>
                <dd>
                  <NotiBox>
                    <NotiList>
                      <NotiItem>
                        수집목적 : 캠페인 관리, 신청 안내 및 문의 응대
                      </NotiItem>
                      <NotiItem>수집항목 : 담당자명, 연락처, 이메일</NotiItem>
                      <NotiItem>
                        보유기간 : 캠페인 종료 또는 철회시까지
                      </NotiItem>
                    </NotiList>
                    <NotiParagraph>
                      ※ 동의를 거부할 수 있으며, 이를 거부하는 경우 신청이
                      제한될 수 있습니다.
                    </NotiParagraph>
                  </NotiBox>
                </dd>
              </dl>

              <FormGroup className="checkbox_wrap">
                <InputCheckbox
                  className="chk-label"
                  ref={register({
                    required: true,
                  })}
                  name="prsnlInfAgrYn"
                  value="Y"
                  label="[필수] 개인정보 수집에 동의합니다."
                  onClick={(event) => {
                    setAgreeCheck(event.currentTarget.checked);
                  }}
                  checked={agreeCheck}
                />

                {errors.prsnlInfAgrYn && (
                  <ErrorMessage>
                    개인정보 수집 동의에 체크해주세요.
                  </ErrorMessage>
                )}
              </FormGroup>
              <ButtonContainer>
                <Button type="submit" className="btn_submit">
                  신청하기
                </Button>
              </ButtonContainer>
            </div>
          </form>
        </Container>
      </Section>
      <Section className="section_courtesy">
        <div className="section-tit">
          <h2 data-aos="fade-up">
            <span>1%의 기적</span>에 <br className="br_m" /> 함께해 주시면
          </h2>
        </div>
        <Container>
          <ul>
            <li data-aos="fade-up">
              <div className="courtesy_icon">
                <Image pcSrc={CourtesyIcon1} mobileSrc={CourtesyIcon1} />
              </div>
              <dl className="courtesy_txt">
                <dt>1% 모금 키트</dt>
                <dd>
                  캠페인 참여를 <br className="br_m" />
                  독려할 수 있는 <br className="br_m" />
                  모금 키트 제공
                </dd>
              </dl>
            </li>
            <li data-aos="fade-up" data-aos-delay="100">
              <div className="courtesy_icon">
                <Image pcSrc={CourtesyIcon5} mobileSrc={CourtesyIcon5} />
              </div>
              <dl className="courtesy_txt">
                <dt>기업∙단체 홍보</dt>
                <dd>
                  협약식, 보도자료 배포, <br className="br_m" />
                  유니세프 웹사이트 및 SNS에 <br className="br_m" />
                  기업∙단체의 후원 활동 홍보
                </dd>
              </dl>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <div className="courtesy_icon">
                <Image pcSrc={CourtesyIcon2} mobileSrc={CourtesyIcon2} />
              </div>
              <dl className="courtesy_txt">
                <dt>참여 인증 배너</dt>
                <dd>
                  참여를 인증할 수 있는 <br />
                  온라인 전용 <br className="br_pc" />
                  홍보 배너 제공
                </dd>
              </dl>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <div className="courtesy_icon">
                <Image pcSrc={CourtesyIcon3} mobileSrc={CourtesyIcon3} />
              </div>
              <dl className="courtesy_txt">
                <dt>감사장</dt>
                <dd>
                  기업∙단체의 <br />
                  이름을 담은 감사장 제작
                </dd>
              </dl>
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              <div className="courtesy_icon">
                <Image pcSrc={CourtesyIcon4} mobileSrc={CourtesyIcon4} />
              </div>
              <dl className="courtesy_txt">
                <dt>기부금 영수증</dt>
                <dd>
                  세제 혜택을 위한 <br className="br_pc" />
                  기부금 영수증 발급 <br />
                  <span>*발급 희망 시 별도 신청 필요</span>
                </dd>
              </dl>
            </li>
          </ul>
          <p>※ 위의 제공 사항은 내부 규정에 따라 달라질 수 있음 </p>
        </Container>
      </Section>

      <SectionContact className="by-sub-main-layout">
        <Container>
          <header>
            <h2 className="a11y">문의</h2>
          </header>
          <ContactUs itemData={contactData} />
        </Container>
      </SectionContact>

      <OnePercentLayerPopup
        isOpen={popupOpen}
        isAgree={agreeCheck}
        dismissable
        onRequestClose={close} /* 창닫기 */
        onSubmit={(data: ModalFormProps) => {
          if (data.isAgreeYn) {
            setAgreeCheck(data.isAgreeYn === 'Y' ? true : false);
          }
          close();
        }} /* 저장 */
      />
    </LayoutWithTitle>
  );
};

export default Miracle;
